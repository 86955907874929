<template>
  <div>
    <b-card>
      <l-map
        :zoom="zoom"
        :center="center"
        :style="full ? 'height: 75vh' : 'height: 50vh'"
      >
        <l-control-fullscreen
          position="topleft"
          :options="{ title: { 'false': 'Go big!', 'true': 'Be regular' } }"
        />
        <l-tile-layer
          :url="url"
        />
        <l-marker
          v-for="marker in locMitra"
          :key="marker.id"
          :lat-lng="marker.mitraMarker"
        >
          <l-icon>
            <b-img
              :src="markerVertikal(marker.data.layanan[0].vertikal)"
              height="40"
            />
          </l-icon>
          <l-popup>
            <div class="d-flex">
              <b-avatar
                class="mr-1"
                :src="marker.data.fotoProfil"
                size="45"
              />
              <div>
                <router-link
                  :to="{ name: 'DetailMitra', params: {id: marker.data.no_hp} }"
                >
                  <h6 class="text-primary m-0 mb-25 font-weight-bold">
                    {{ marker.data.nama }}
                  </h6>
                </router-link>
                <small>{{ marker.data.no_hp }}</small>
                <small class="text-secondary d-block">{{ vertikalLayanan(marker.data.layanan[0].vertikal) }}</small>
              </div>
            </div>
          </l-popup>
        </l-marker>
      </l-map>
      <!-- keterangan maps -->
      <!-- eslint-disable vue/singleline-html-element-content-newline -->
      <!-- eslint-disable vue/max-attributes-per-line -->
      <!-- eslint-disable vue/html-closing-bracket-spacing -->
      <h6 class="mt-1">Keterangan :</h6>
      <b-row class="d-flex">
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color:#1ABC9C;"/>
            <small>Massage</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #DE3163;"/>
            <small>Akupunktur</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #6495ED;"/>
            <small>Cleaning</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #FF77B1;"/>
            <small>Beauty</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #DFFF00;"/>
            <small>Bekam</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #9B59B6;"/>
            <small>Auto</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #FA8072;"/>
            <small>Barber</small>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <div class="box mr-50 mb-50" style="background-color: #E67E22;"/>
            <small>Cuci AC</small>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCol,
  BRow,
} from 'bootstrap-vue'
import 'leaflet/dist/leaflet.css'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
} from 'vue2-leaflet'
import LControlFullscreen from 'vue2-leaflet-fullscreen'

/*  */
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    BCard,
    LPopup,
    BAvatar,
    BImg,
    BCol,
    BRow,
    LControlFullscreen,
  },
  props: ['mitra'],
  data() {
    /* eslint-disable global-require */
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [-7.536064, 112.238403],
      locMitra: [],
      full: '',
    }
  },
  created() {
    this.getMitra()
  },
  methods: {
    getMitra() {
      /* eslint-disable no-underscore-dangle */
      const arr = []
      const arr2 = []
      this.mitra.forEach(doc => {
        if (doc.posisi != null && doc.posisi.geopoint != null) {
          arr.push({
            id: doc.no_hp,
            data: doc,
            mitraMarker: [
              doc.posisi.geopoint?._latitude,
              doc.posisi.geopoint?._longitude,
              { draggable: 'true' },
            ],
          })
        } else {
          arr2.push({
            data: doc,
          })
        }
      })
      this.locMitra = [...new Set(arr)]
    },
    vertikalLayanan(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }
      return 'Layanan Belum Diisi'
    },
    markerVertikal(vl) {
      if (vl) {
        const markerVariant = {
          vl01: require('@/assets/images/icons/akupuntur.png'),
          vl02: require('@/assets/images/icons/auto.png'),
          vl03: require('@/assets/images/icons/barber.png'),
          vl04: require('@/assets/images/icons/bekam.png'),
          vl05: require('@/assets/images/icons/beauty.png'),
          vl06: require('@/assets/images/icons/cleaning.png'),
          vl07: require('@/assets/images/icons/massage.png'),
          vl08: require('@/assets/images/icons/cuci-ac.png'),
        }
        return markerVariant[vl]
      }
      return 'error'
    },
  },
}
</script>

<style lang="scss" scoped>
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}

.box {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: #c8c8c8;
}
</style>
